import { GeneralService } from './../../../core/services/general.service';
import { Component, Input, OnInit, HostListener, SimpleChanges, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Tool } from '../../../core/interfaces/tool';
import { ToolsStoreService } from '../../../core/services/tools-store.service';
import { AuthService } from '../../../routes/user/auth.service';
import { interval } from 'rxjs';
import { PreventiveStoreService } from '../../../core/services/preventive-store.service';
import { TranslationsStoreService } from '../../../core/services/translations-store.service';
import { CommonService } from '../../../core/services/common.service';
import { FilesStoreService } from '../../../core/services/files-store.service';
import { isString } from 'lodash';

@Component({
  selector: 'app-tool-image',
  templateUrl: './tool-image.component.pug',
  styleUrls: ['./tool-image.component.scss'],
})
export class ToolImageComponent implements OnInit {
  screen_width: any;
  subscribe: any;
  isFreeze: boolean = false;
  isHide: boolean = true;
  @Input() tool: Tool;
  @Input() type: string = "";
  @Input() isMobile: boolean = false;
  @Input() isChangeImage: boolean = false;
  @Input() size: string = 'large';
  @Output() changePictureAfterEvent = new EventEmitter<boolean>();
  imageUrl: string;

  constructor(private service: ToolsStoreService,
    public auth: AuthService,
    private preventiveService: PreventiveStoreService,
    private changeDetectorRef: ChangeDetectorRef,
    public translationService: TranslationsStoreService,
    public commonService: CommonService,
    private generalService: GeneralService,
    private fileService: FilesStoreService) {
    this.onResize();
  }

  async ngOnInit() {
    this.preventiveService.maints$.subscribe(maints => {
      const toolMaintenances = maints.filter(maint => maint.tool && (maint.tool === this.tool._id || maint.tool._id === this.tool._id));
      const activeMaints = toolMaintenances.filter(maint => !maint.isDeleted && maint.isFreeze).toArray();
      if (activeMaints.length > 0) {
        this.isFreeze = true;
      }
    })
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes.isChangeImage && changes.isChangeImage.currentValue == true) {
      $('.tool-image-' + this.tool._id + ' input[type="file"]').trigger('click');
      this.emitEvent();
    }
    if (changes.tool && changes.tool.currentValue) {
      this.imageUrl = this.tool.image ? await this.getFileUrl(this.tool.image) : 'assets/img/noImage.png';
      this.changeDetectorRef.detectChanges();
    }
  }

  getFileUrl = (file: string): Promise<string> => {
    return this.fileService.signedUrl(file, this.size).catch(() => 'assets/img/noImage.png');
  }

  emitEvent() {
    this.subscribe = interval(1000).subscribe(() => {
      this.changePictureAfterEvent.emit(false);
      this.subscribe.unsubscribe();
    });
  }

  // noinspection JSMethodCanBeStatic
  onUploadClick($event, uploadctl) {
    $event.stopPropagation();
    $event.preventDefault();
    uploadctl.click();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screen_width = window.innerWidth;
  }

  uploadPicture($event) {
    if (!this.tool || !$event.target.files.length || !$event.target.files[0].name) {
      return;
    }
    if(this.commonService.checkImageFileExtention($event)){
      this.service.uploadPicture(this.tool.id_num, $event.target.files);
    }
  }

  loaded = () => {
    this.isHide = false;
  }

  getStyle = () => {
    return {
      '-webkit-transform': `rotate(${this.tool.rotate}deg)`,
      '-moz-transform': `rotate(${this.tool.rotate}deg)`,
      '-o-transform': `rotate(${this.tool.rotate}deg)`,
      '-ms-transform': `rotate(${this.tool.rotate}deg)`,
      transform: `rotate(${this.tool.rotate}deg)`,
    };
  }

  viewFile = async($event) => {
    $event.stopPropagation();
    $event.preventDefault();
    this.generalService.viewFile(this.tool.image);
  }

  downloadFile = async($event) => {
    $event.stopPropagation();
    $event.preventDefault();
    this.generalService.downloadFile({
      _id: this.tool.image
    });
  }

}
