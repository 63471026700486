import { CompaniesStoreService } from "./companies-store.service";
import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, of } from "rxjs";
import { List, setIn } from "immutable";
import { Tool } from "../interfaces/tool";
import { ToolsBackendService } from "./backend/tools.backend.service";
import { ToolColumn } from "../interfaces/tool_column";
import { map, shareReplay, take, tap } from "rxjs/operators";
import { AuthService } from "../../routes/user/auth.service";
import { EventsStoreService } from "./events-store.service";
import { CustomFieldsStoreService } from "./custom-fields-store.service";
import FieldDefinition from "../constants/field-definitions/tools";
import StatusFieldDefinition from "../constants/field-definitions/status";
import DrawingFieldDefinition from "../constants/field-definitions/drawing";
import { UsersStoreService } from "./users-store.service";
import { concat, isString, uniqBy } from "lodash";
import { CommonService } from "./common.service";
import { MenuStoreService } from "./menu-store.service";
import BluebirdPromise from "bluebird";
import { TOOL_OBJECT } from "../interfaces/general";
import { SitesStoreService } from "./sites-store.service";
import { TranslateService } from "@ngx-translate/core";
import { ConfirmService } from "../utils/confirm/confirm.service";
import { Router } from "@angular/router";
import { ConfirmType } from "../constants/confirm-result.enum";
import { TranslationsStoreService } from "./translations-store.service";
import moment from "moment-timezone";
import { ColDef } from "ag-grid-community";
import { GeneralService } from "./general.service";
import { PreventiveStoreService } from "./preventive-store.service";
@Injectable({
  providedIn: "root",
})
export class ToolsStoreService {
  public toolsUpdated: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private toolList = [];

  public toolsDeletedSubject: BehaviorSubject<List<Tool>> = new BehaviorSubject(
    List([])
  );
  public readonly toolsDeleted$: Observable<List<Tool>> =
    this.toolsDeletedSubject.asObservable();

  public toolPathSubject: BehaviorSubject<TOOL_OBJECT> = new BehaviorSubject(
    {}
  );

  private columnsSubject: BehaviorSubject<ColDef[]> = new BehaviorSubject([]);
  public readonly columns$: Observable<ColDef[]>;

  public readonly statusColumns$: Observable<ColDef[]>;

  public readonly drawingColumns$: Observable<ColDef[]>;

  public files$: BehaviorSubject<any[]> = new BehaviorSubject([]);

  private lastFetchedTime: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(
    private backend: ToolsBackendService,
    private customFieldsService: CustomFieldsStoreService,
    private usersStoreService: UsersStoreService,
    private companiesStoreService: CompaniesStoreService,
    private menuStoreService: MenuStoreService,
    private auth: AuthService,
    private eventsService: EventsStoreService,
    private commonService: CommonService,
    private sitesStoreService: SitesStoreService,
    private translate: TranslateService,
    private confirm: ConfirmService,
    private translationsStoreService: TranslationsStoreService,
    private generalService: GeneralService,
    private preventiveStoreService: PreventiveStoreService,
    private router: Router
  ) {
    this.auth.user$.subscribe((user) => {
      if (!user || !user._id || user.isRoot()) {
        this.columnsSubject.next([]);
        return;
      }
      this.load().subscribe();
    });

    this.columns$ = combineLatest([
      this.auth.user$,
      this.customFieldsService.get("tools"),
      this.translationsStoreService.languageCode$,
      this.companiesStoreService.companies$,
    ]).pipe(
      map(([user, columns, languageCode, companies]) => {
        if (!user) return [];
        const hiddenRegularColumns = columns
          .filter((c) => c.isRegularColumn)
          .toArray();
        let defaults = FieldDefinition.FieldDefinition.filter(
          (col) =>
            (col.name != "company.name" || user.isRoot()) &&
            (col.name != "isDeleted" || user.isAdminOrToolAdmin())
        )
          .filter(
            (col) =>
              col.name != "drawing" ||
              this.commonService.hasDrawingModePermission()
          )
          .filter(
            (col) =>
              col.name != "hideDataInGraphs" ||
              this.commonService.hasAllowHideDataInGraphs()
          )
          .filter(
            (col) =>
              col.name != "safety" || this.commonService.hasSafetySignature()
          )
          .filter(
            (col) =>
              (col.name != "tool" && col.name != "toolObject.id_num") ||
              this.commonService.hasSubSystems()
          )
          .map((col) => {
            if (col.name === "group") {
              col.name = "group.name";
              col.readonly = true;
              col.type = "textbox";
            }
            const f = hiddenRegularColumns.find((c) => c.name === col.name);
            if (f) {
              col.hide = f.hide;
              col.required = f.required;
              col.dontPresentOnModal = f.dontPresentOnModal;
              col.preventEditOnModal = f.preventEditOnModal;
              col.chartDataType = f.chartDataType;
              col.rules = f.rules;
              col.isRegularColumn = f.isRegularColumn;
            }
            return col;
          });
        const fields = columns.toArray().filter((c) => !c.isRegularColumn);

        const col = this.customFieldsService.toColumnDef(defaults, {
          // rowSelector: "code",
          rowSelector: "id_num",
          table: "tool",
          format: this.formatCell.bind(this),
        });

        const custom = this.customFieldsService.toColumnDef(fields, {
          format: this.customFieldsService.formatCellCustomField.bind(this),
        });
        return col.concat(custom);
      })
    );

    this.drawingColumns$ = combineLatest([
      this.auth.user$,
      this.customFieldsService.get("tools-drawing"),
      this.translationsStoreService.languageCode$,
    ]).pipe(
      map(([user, columns, languageCode]) => {
        if (!user) return [];
        let defaults = DrawingFieldDefinition.FieldDefinition;
        const fields = columns.toArray();

        const col = this.customFieldsService.toColumnDef(defaults, {
          // rowSelector: "code",
          rowSelector: "id_num",
          table: "tool",
          format: this.formatCell.bind(this),
        });

        const custom = this.customFieldsService.toColumnDef(fields, {
          format: this.customFieldsService.formatCellCustomField.bind(this),
        });
        return col.concat(custom);
      })
    );

    this.statusColumns$ = combineLatest([
      this.auth.user$,
      this.translationsStoreService.languageCode$,
    ]).pipe(
      map(([user, languageCode]) => {
        if (!user) return [];
        let defaults = StatusFieldDefinition.FieldDefinition.filter(
          (col) => col.name != "action" || user.isAdminOrToolAdmin()
        );

        const col = this.customFieldsService.toColumnDef(defaults, {
          rowSelector: "id_num",
          table: "status",
          format: this.statusFormatCell.bind(this),
        });
        return col;
      })
    );

    this.toolPath();
  }

  getToolList() {
    return this.toolList;
  }

  getGroupSelectList() {
    return this.toolList
      .filter((t) => t.isGroup && !t.isDeleted)
      .map((t) => ({ id: t._id, text: t.name }));
  }

  getToolSelectList() {
    return this.toolList
      .filter((t) => !t.isGroup && !t.isDeleted)
      .map((t) => ({ id: t._id, text: t.name }));
  }

  getToolNameSelectList() {
    return this.toolList
      .filter((t) => !t.isGroup && !t.isDeleted)
      .map((t) => ({ id: t.name, text: t.name }));
  }

  getToolSelectListBySubTool() {
    const tools = this.toolList.filter((t) => !t.isGroup && !t.isDeleted);
    const alreadyAdded = [];
    const lists = [];
    for (let i = 0; i < tools.length; i++) {
      const tool = tools[i];
      if (alreadyAdded.includes(tool._id)) continue;
      const subTools = tools.filter(
        (t) => t.isSubSystem && t.tool === tool._id
      );
      lists.push({ id: tool._id, text: tool.name });
      alreadyAdded.push(tool._id);
      subTools.forEach((subTool) => {
        lists.push({ id: subTool._id, text: `${subTool.name} (${tool.name})` });
        alreadyAdded.push(subTool._id);
      });
    }
    return lists;
  }

  load() {
    const time = moment().tz("UTC").format("YYYY-MM-DD HH:mm:ss");
    return this.backend.list(this.lastFetchedTime.getValue()).pipe(
      map((tools) => tools.map((tool: Tool) => new Tool(tool))),
      map((tools) => tools.map((tool: Tool) => this.remapTool(tool, tools))),
      tap((tools: Array<Tool>) => {
        this.lastFetchedTime.next(time);
        if (!this.lastFetchedTime.getValue()) {
          this.toolList = tools;
        } else {
          const prev = [...this.toolList];
          this.toolList = uniqBy([...tools, ...prev], "_id");
        }
        this.toolsUpdated.next(true);
      })
    );
  }

  deletedList() {
    if (this.toolsDeletedSubject.getValue().toArray().length > 0) {
      return of(false);
    } else {
      return this.subDeletedList().subscribe();
    }
  }

  subDeletedList() {
    return this.backend.deletedList().pipe(
      map((tools) => tools.map((tool: Tool) => new Tool(tool))),
      map((tools) => tools.map((tool: Tool) => this.remapTool(tool, tools))),
      tap((tools: Array<Tool>) => this.toolsDeletedSubject.next(List(tools)))
    );
  }

  search(search: object) {
    return this.backend.search(search).pipe(
      map((tools) => tools.map((tool: Tool) => new Tool(tool))),
      map((tools) => tools.map((tool: Tool) => this.remapTool(tool, []))),
      tap((tools: Array<Tool>) => {
        return tools;
      })
    );
  }

  getTool(toolId: number | string): Observable<Tool> {
    return this.toolsUpdated.pipe(
      map((tools) =>
        this.getToolList().find(
          (tool) =>
            tool[typeof toolId === "string" ? "_id" : "id_num"] === toolId
        )
      ),
      shareReplay(1)
    );
  }

  getToolById(toolId: string) {
    const tools = this.getTools();
    const toolIndex = this.getTools().findIndex(
      (tool) => tool["_id"] === toolId
    );
    return toolIndex > -1 ? tools[toolIndex] : null;
  }

  getToolByIdNum(toolId: number) {
    const tools = this.getTools();
    const toolIndex = this.getTools().findIndex(
      (tool) => tool["id_num"] === toolId
    );
    return toolIndex > -1 ? tools[toolIndex] : null;
  }

  remapTool = (tool, tools) => {
    if (tools.length > 0 && tool.group) {
      const t = tools.findIndex((t) => t._id == tool.group);
      if (t > -1) {
        tool.group = tools[t];
      }
    }
    if (tools.length > 0 && tool.pointers) {
      const toolPointers = tools.filter(
        (t) => tool.pointers.indexOf(t._id) > -1
      );
      tool.pointersObject = toolPointers;
    }
    if (tools.length > 0 && tool.tool) {
      if (isString(tool.tool)) {
        const t = tools.findIndex((t) => t._id == tool.tool);
        if (t > -1) {
          tool.toolObject = tools[t];
        }
      } else {
        tool.toolObject = tool;
        tool.tool = tool._id;
      }
    }
    if (tool.lastUpdatedEvent) {
      tool.lastUpdatedEvent = new Date(tool.lastUpdatedEvent);
    }
    if (tool.sendTo && tool.sendTo.length > 0) {
      const users = this.usersStoreService.getList().toArray();
      tool.sendToObject = tool.sendTo.map((s) => {
        const user = users.findIndex((u) => u._id.toString() == s.toString());
        return user > -1 ? users[user] : null;
      });
    }
    if (tool.inventoryParts && tool.inventoryParts.length > 0) {
      tool.inventoryPartsObject = tool.inventoryParts;
      tool.inventoryParts = tool.inventoryParts.map(
        (p: { _id: string }) => p._id
      );
    }
    if (tool.customTable && tool.customTable.length > 0) {
      const menus = this.menuStoreService.getList().toArray();
      tool.customTableObject = tool.customTable.map((c) => {
        const menu = menus.findIndex((m) => m._id.toString() == c.toString());
        return menu > -1 ? menus[menu] : null;
      });
    }
    if (tool.site) {
      if (isString(tool.site)) {
        const sites = this.sitesStoreService.getList().toArray();
        const site = sites.findIndex((s) => s._id == tool.site);
        if (site > -1) {
          tool.siteObject = sites[site];
          tool.site = tool.siteObject._id;
        }
      } else {
        tool.siteObject = tool.site || {};
        tool.site =
          typeof tool.siteObject._id != "undefined"
            ? tool.siteObject._id
            : null;
      }
    }
    tool.keywordsObject = tool.keywords || [];
    tool.keywords =
      tool.keywordsObject.length > 0
        ? tool.keywordsObject.map((a: { _id: string }) => a._id)
        : null;

    tool.custom = tool.custom || {};
    tool.imageUrl = tool.imageUrl || "assets/img/noImage.png";
    if (tool.image) {
      tool.imageUrl = `/api/tools/image/${tool.image}`;
    }

    tool.groupId = tool.group ? tool.group.id_num : "";
    return tool;
  };

  getFiles(toolId: string, page: number, limit: number) {
    return this.backend
      .listFiles(toolId, page, limit)
      .pipe(map((files) => List(files)));
  }

  getParts(toolId: number) {
    return this.backend.listParts(toolId).pipe(map((parts) => List(parts)));
  }

  getFilesCount(toolId: string) {
    return this.backend.listFilesCount(toolId);
  }

  commonUpdate(data: Tool) {
    return this.backend.update(this.customFieldsService.includeUrl(data)).pipe(
      map((tool) => new Tool(tool)),
      map((tool) => this.remapTool(tool, this.getToolsArray())),
      tap((tool: Tool) => {
        const tools = [...this.toolList];
        const idx = tools.findIndex((t: Tool) => t._id === data._id);

        if (tool.isGroup) {
          tools
            .filter((t) => t.group && t.group._id === tool._id)
            .forEach((t) => (t.group = tool));
        } else {
          this.eventsService.patchEvents(tool);
        }
        tools[idx] = tool;
        this.toolList = tools;
        this.toolsUpdated.next(true);
      })
    );
  }

  updateToolList(tool: Tool) {
    const tools = [...this.toolList];
    const idx = tools.findIndex((t: Tool) => t._id === tool._id);
    tools[idx] = tool;
    this.toolList = tools;
    this.toolsUpdated.next(true);
  }

  updateTool(data: Tool) {
    return this.commonUpdate(data);
  }

  update(data: Tool) {
    return this.commonUpdate(data);
  }

  rotatePicture(toolId: string) {
    return this.backend.rotatePicture(toolId).pipe(
      tap(() => {
        const tools = [...this.toolList];
        const idx = tools.findIndex(
          (t: Tool) => t._id.toString() === toolId.toString()
        );
        const tool = tools.find(
          (t: Tool) => t._id.toString() === toolId.toString()
        );
        const rotate = tool.rotate + 90;
        tools[idx] = {
          ...tool,
          rotate: rotate > 270 ? 0 : rotate,
        };
        this.toolList = tools;
        this.toolsUpdated.next(true);
      })
    );
  }

  updateMultipleTools(data: Tool[]) {
    return this.backend
      .updateMultiple(data.map((d) => this.customFieldsService.includeUrl(d)))
      .pipe(
        map((tools) =>
          tools.map((tool: Tool) => this.remapTool(tool, this.getToolsArray()))
        ),
        tap((tools: Array<Tool>) => {
          const toolsArray = [...this.toolList];
          tools.forEach((tool) => {
            const idx = toolsArray.findIndex((t: Tool) => t._id === tool._id);
            if (idx > -1) {
              if (tool.isGroup) {
                tools
                  .filter((t) => t.group && t.group._id === tool._id)
                  .forEach((t) => (t.group = tool));
              } else {
                this.eventsService.patchEvents(tool);
              }
              if (tool.isDeleted) {
                toolsArray.splice(idx, 1);
              } else {
                toolsArray[idx] = tool;
              }
            } else {
              toolsArray.unshift(tool);
            }

            const toolsDeleted = this.toolsDeletedSubject.getValue().toArray();
            const index = this.toolsDeletedSubject
              .getValue()
              .findIndex((t) => t._id === tool._id);
            if (index > -1) {
              toolsDeleted.splice(index, 1);
              this.toolsDeletedSubject.next(List(toolsDeleted));
            } else if (tool.isDeleted) {
              toolsDeleted.unshift(tool);
              this.toolsDeletedSubject.next(List(toolsDeleted));
            }
          });
          this.toolList = toolsArray;
          this.toolsUpdated.next(true);
        })
      );
  }

  delete(id: String) {
    return this.backend.delete(id).pipe(
      tap((tool: Tool) => {
        const tools = [...this.toolList];
        const idx = tools.findIndex((t: Tool) => t._id === id);
        if (this.auth.isAdmin()) {
          let tool = tools[idx];
          tool["isDeleted"] = true;
          if (tool.isGroup) {
            tools.forEach((data, index) => {
              if (
                data.group &&
                ((isString(data.group) && data.group == tool._id) ||
                  data.group._id == tool._id)
              ) {
                data.group = null;
                tools[index] = data;
              }
            });
          }
          tools[idx] = tool;
          this.toolList = tools;
          this.toolsUpdated.next(true);
        } else {
          tools.splice(idx, 1);
          this.toolList = tools;
          this.toolsUpdated.next(true);
        }
      })
    );
  }

  syncTool(id: string) {
    return this.backend.getOne(id).pipe(
      map((tool) => new Tool(tool)),
      map((tool) => this.remapTool(tool, this.getToolsArray())),
      tap((tool: Tool) => {
        const tools = [...this.toolList];
        const idx = tools.findIndex((t: Tool) => t._id === tool._id);

        if (idx > -1) {
          if (tool.isGroup) {
            tools
              .filter((t) => t.group && t.group._id === tool._id)
              .forEach((t) => (t.group = tool));
          } else {
            this.eventsService.patchEvents(tool);
          }
          tools[idx] = tool;
        } else {
          tools.unshift(tool);
        }
        this.toolList = tools;
        this.toolsUpdated.next(true);
      })
    );
  }

  uploadPicture(toolId, files) {
    this.backend.uploadPicture(toolId, files).subscribe((tool: Tool) => {
      const tools = [...this.toolList];
      const idx = tools.findIndex((t: Tool) => t.id_num === toolId);
      tools[idx] = tool;
      this.toolList = tools;
      this.toolsUpdated.next(true);
      this.files$.next([]);
    });
  }

  choosePicture(toolId: string, url: string) {
    this.backend
      .choosePicture(toolId, url)
      .subscribe((res: { image: string }) => {
        const tools = [...this.toolList];
        const idx = tools.findIndex((t: Tool) => t._id.toString() === toolId);
        const tool = tools.find((t: Tool) => t._id.toString() === toolId);
        tools[idx] = {
          ...tool,
          image: res.image,
        };
        this.toolList = tools;
        this.toolsUpdated.next(true);
        this.files$.next([]);
      });
  }

  public getTools() {
    return this.toolList;
  }

  public getToolsCount() {
    return this.getTools().filter((tool) => !tool.isGroup && !tool.isDeleted)
      .length;
  }

  public getGroups() {
    return this.toolList.filter((tool) => tool.isGroup && !tool.isDeleted);
  }

  public getToolsArray() {
    return this.getTools();
  }

  public getDeletedToolsArray() {
    return this.toolsDeletedSubject.getValue().toArray();
  }

  public getOnlyTools() {
    return this.getTools().filter((tool) => !tool.isGroup && !tool.isSubSystem);
  }

  public create(tool: Tool) {
    return this.backend.create(this.customFieldsService.includeUrl(tool)).pipe(
      map((tl) => new Tool(tl)),
      map((tl) => this.remapTool(tl, this.getToolsArray())),
      tap((tl) => {
        const tools = [...this.toolList];
        tools.unshift(tl);
        this.toolList = tools;
        this.toolsUpdated.next(true);
      })
    );
  }

  public createSubSystemFromTool(data: {
    tool: string;
    subSystemTool: string;
  }) {
    return this.backend
      .createSubSystemFromTool(this.customFieldsService.includeUrl(data))
      .pipe(
        map((tl) => new Tool(tl)),
        map((tl) => this.remapTool(tl, this.getToolsArray())),
        tap((tl) => {
          const tools = [...this.toolList];
          const idx = tools.findIndex((t: Tool) => t._id === tl._id);

          if (tl.isGroup) {
            tools
              .filter((t) => t.group && t.group._id === tl._id)
              .forEach((t) => (t.group = tl));
          } else {
            this.eventsService.patchEvents(tl);
          }
          tools[idx] = tl;
          this.toolList = tools;
          this.toolsUpdated.next(true);
        })
      );
  }

  // noinspection JSMethodCanBeStatic
  mapColumns(columns: ToolColumn[]) {
    return columns.map((col) => ({
      field: `custom.${col.name}`,
      headerName: col.title,
    }));
  }

  public updateChildCount = async (tools: Tool[]) => {
    const tls = [];
    const that = this;
    await BluebirdPromise.Promise.map(tools, async (t) => {
      const pointerTools = t?.pointers || [];
      function toolChild(id) {
        const list = that
          .getTools()
          .filter((cur) => {
            const pTools = cur.pointers || [];
            return (
              ((cur.group && cur.group._id && cur.group._id === id) ||
                pTools.includes(cur._id)) &&
              !cur.isDeleted
            );
          })
          .map((cur) => {
            if (cur.isGroup) {
              return toolChild(cur._id);
            } else {
              return 1;
            }
          });
        const number =
          list.length > 0 ? list.reduce((a: number, b: number) => a + b) : 0;
        return number ? number : 0;
      }
      if (t.isGroup) {
        t.children = that
          .getTools()
          .filter(
            (cur) =>
              !cur.isDeleted &&
              ((cur.group && cur.group._id && cur.group._id === t._id) ||
                pointerTools.includes(cur._id))
          ).length;
        const list = that
          .getTools()
          .filter(
            (cur) =>
              !cur.isDeleted &&
              ((cur.group && cur.group._id && cur.group._id === t._id) ||
                pointerTools.includes(cur._id))
          )
          .map((cur) => {
            if (cur.isGroup) {
              return toolChild(cur._id);
            } else {
              return 1;
            }
          });
        t.totalChildren =
          list.length > 0 ? list.reduce((a: number, b: number) => a + b) : 0;
      }
      tls.push(t);
    });
    return tls;
  };

  private toolPath = () => {
    const that = this;
    this.toolsUpdated.subscribe(async (toolsUpdated) => {
      let tPath: any = {};
      await BluebirdPromise.Promise.mapSeries(
        this.getToolsArray().filter((t) => !t.isDeleted),
        async (tool) => {
          const tg: any = await that.getRelatedGroups(tool);
          tPath[tool._id] = {
            path: tg && tg.length > 0 ? tg.map((g) => g.name).join(" > ") : "",
            pathGroups: tg,
            tools: tool.isGroup ? await that.getRelatedTools(tool) : [],
            subSystems: await that.getRelatedSubSystem(tool),
          };
        }
      );
      this.toolPathSubject.next(tPath);
    });
  };

  getToolBreadCrumb = (toolId: string) => {
    const paths = this.toolPathSubject.getValue();
    return typeof paths[toolId] != "undefined" ? paths[toolId].path : "";
  };

  getToolBreadCrumbGroups = (toolId: string) => {
    const paths = this.toolPathSubject.getValue();
    return typeof paths[toolId] != "undefined" ? paths[toolId].pathGroups : [];
  };

  getRelatedGroups = async (tool) => {
    return new Promise(async (resolve, reject) => {
      let res = [];
      if (tool.group) {
        const groups = this.getGroups();
        const group = groups.find(
          (g) =>
            (tool.group._id && g._id.toString() == tool.group._id.toString()) ||
            g._id.toString() == tool.group.toString()
        );
        if (group) {
          res = concat(group, res);
          if (group.group) {
            const insideGroup: any = await this.getRelatedGroups(group);
            if (insideGroup.length > 0) {
              res = concat(insideGroup, res);
            }
          }
        }
      } else if (tool.tool) {
        const toolId = tool.tool._id ? tool.tool._id : tool.tool;
        const tools = this.getToolsArray();
        const toolObj = tools.find(
          (t) => t._id.toString() == toolId.toString()
        );
        if (toolObj && toolObj.group) {
          const groups = this.getGroups();
          const group = groups.find(
            (g) =>
              (toolObj.group._id &&
                g._id.toString() == toolObj.group._id.toString()) ||
              g._id.toString() == toolObj.group.toString()
          );
          if (group) {
            res = concat(group, res);
            if (group.group) {
              const insideGroup: any = await this.getRelatedGroups(group);
              if (insideGroup.length > 0) {
                res = concat(insideGroup, res);
              }
            }
            res = concat(res, toolObj);
          }
        }
      }
      resolve(res);
    });
  };

  getRelatedTools = async (group) => {
    return new Promise(async (resolve, reject) => {
      let res = [];
      if (group && group.isGroup) {
        const mixed = this.getToolsArray().filter(
          (t) =>
            t.group &&
            ((t.group._id && group._id.toString() == t.group._id.toString()) ||
              group._id.toString() == t.group.toString())
        );
        const tools = mixed.filter((m) => !m.isGroup).map((m) => m._id);
        if (tools) res = concat(tools, res);
        const groups = mixed.filter((m) => m.isGroup);
        await BluebirdPromise.Promise.mapSeries(groups, async (gp) => {
          const insideTools: any = await this.getRelatedTools(gp);
          if (insideTools.length > 0) {
            res = concat(insideTools, res);
          }
        });
      }
      resolve(res);
    });
  };

  getRelatedSubSystem = async (tool) => {
    return new Promise(async (resolve, reject) => {
      const res = this.getToolsArray().filter(
        (t) => t.isSubSystem && t.tool === tool._id
      );
      resolve(res.length > 0 ? res.map((t) => t._id) : []);
    });
  };

  formatCell = (col, field) => {
    switch (field.name) {
      case "name":
        return {
          ...col,
          cellRenderer: "customClick",
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name,
          },
        };
      case "isGroup":
        return {
          ...col,
          valueFormatter: (params) => this.getType(params),
          valueGetter: (params) => this.getType(params),
        };

      default:
        return col;
    }
  };

  statusFormatCell = (col, field) => {
    switch (field.name) {
      case "name":
        return {
          ...col,
          cellRenderer: "customClick",
          cellRendererParams: {
            onClick: this.toolLink.bind(this),
            field: field.name,
          },
        };
      case "isGroup":
        return {
          ...col,
          valueFormatter: (params) => this.getType(params),
          valueGetter: (params) => this.getType(params),
        };
      case "toolStatus":
        return {
          ...col,
          valueFormatter: (params) => this.getToolStatus(params),
          valueGetter: (params) => this.getToolStatus(params),
          tooltipValueGetter: (params) => this.getToolStatus(params),
        };
      case "image":
        return {
          ...col,
          cellRenderer: "button",
          cellRendererParams: {
            onClick: this.imageLink.bind(this),
            label: this.translate.instant("general.SHOW-IMAGE"),
            visible: (params) => this.visibleImage(params),
          },
          valueFormatter: (params) => null,
        };

      default:
        return col;
    }
  };

  visibleImage = (params) => {
    return params.image ? true : false;
  };

  imageLink = (params) => {
    if (params.rowData.image) {
      this.generalService.viewFile(params.rowData.image);
    }
  };

  getType = (params) => {
    if (params.data.isGroup) return this.translate.instant("tool.GROUP");
    else if (params.data.isSubSystem)
      return this.translate.instant("tool.SUB-SYSTEM");
    else return this.translate.instant("tool.TOOL");
  };

  getToolStatus = (params) => {
    const tool = params.data;
    if (tool) {
      const tools = this.toolPathSubject.getValue();
      const maints =
        this.preventiveStoreService.toolWiseMaintenanceSubject.getValue();
      const events = this.eventsService.toolWiseEventSubject.getValue();
      let hasSubSystems = false;
      let groupStatus = null;
      let toolStatus = null;
      let totalModerateEvents = 0;
      let totalQaEvents = 0;
      let totalSafetyEvents = 0;
      let totalSeverityOpenEvent = 0;
      if (!tool.isGroup) {
        if (tools[tool._id] && tools[tool._id].subSystems.length > 0) {
          hasSubSystems = true;
          const gStatus = [];
          let moderateEvents = 0;
          let qaEvents = 0;
          let safetyEvents = 0;
          if (tools[tool._id].subSystems) {
            const m =
              typeof maints[tool._id] != "undefined" ? maints[tool._id] : null;
            const e =
              typeof events[tool._id] != "undefined" ? events[tool._id] : null;
            if (m || e) {
              const s = Math.max(m ? m.status : null, e ? e.status : null);
              gStatus[s] =
                (gStatus[s] || 0) +
                (m && m.severity[s] ? m.severity[s] : 0) +
                (e && e.severity[s] ? e.severity[s] : 0);
              moderateEvents += e ? e.moderateEvents : 0;
              qaEvents += e ? e.qaEvents : 0;
              safetyEvents += e ? e.safetyEvents : 0;
            }
            tools[tool._id].subSystems.forEach((tool) => {
              const m =
                typeof maints[tool] != "undefined" ? maints[tool] : null;
              const e =
                typeof events[tool] != "undefined" ? events[tool] : null;
              if (m || e) {
                const s = Math.max(m ? m.status : null, e ? e.status : null);
                gStatus[s] =
                  (gStatus[s] || 0) +
                  (m && m.severity[s] ? m.severity[s] : 0) +
                  (e && e.severity[s] ? e.severity[s] : 0);
                moderateEvents += e ? e.moderateEvents : 0;
                qaEvents += e ? e.qaEvents : 0;
                safetyEvents += e ? e.safetyEvents : 0;
              }
            });
          }
          groupStatus = gStatus;
          totalModerateEvents = moderateEvents;
          totalQaEvents = qaEvents;
          totalSafetyEvents = safetyEvents;
        } else {
          const m =
            typeof maints[tool._id] != "undefined" ? maints[tool._id] : null;
          const e =
            typeof events[tool._id] != "undefined" ? events[tool._id] : null;
          toolStatus = Math.max(m ? m.status : null, e ? e.status : null);
          totalSeverityOpenEvent =
            (m && m.severity[toolStatus] ? m.severity[toolStatus] : 0) +
            (e && e.severity[toolStatus] ? e.severity[toolStatus] : 0);
          totalModerateEvents = e ? e.moderateEvents : 0;
          totalQaEvents = e ? e.qaEvents : 0;
          totalSafetyEvents = e ? e.safetyEvents : 0;
        }
      } else {
        const gStatus = [];
        let moderateEvents = 0;
        let qaEvents = 0;
        let safetyEvents = 0;
        if (tools[tool._id]) {
          tools[tool._id].tools.forEach((tool) => {
            const m = typeof maints[tool] != "undefined" ? maints[tool] : null;
            const e = typeof events[tool] != "undefined" ? events[tool] : null;
            if (m || e) {
              const s = Math.max(m ? m.status : null, e ? e.status : null);
              gStatus[s] = (gStatus[s] || 0) + 1;
              moderateEvents += e ? e.moderateEvents : 0;
              qaEvents += e ? e.qaEvents : 0;
              safetyEvents += e ? e.safetyEvents : 0;
            }
            if (tools[tool] && tools[tool].subSystems.length > 0) {
              tools[tool].subSystems.forEach((t) => {
                const m = typeof maints[t] != "undefined" ? maints[t] : null;
                const e = typeof events[t] != "undefined" ? events[t] : null;
                if (m || e) {
                  const s = Math.max(m ? m.status : null, e ? e.status : null);
                  gStatus[s] = (gStatus[s] || 0) + 1;
                  moderateEvents += e ? e.moderateEvents : 0;
                  qaEvents += e ? e.qaEvents : 0;
                  safetyEvents += e ? e.safetyEvents : 0;
                }
              });
            }
          });
        }
        groupStatus = gStatus;
        totalModerateEvents = moderateEvents;
        totalQaEvents = qaEvents;
        totalSafetyEvents = safetyEvents;
      }
      const PM = this.translate.instant("general.PM");
      const IDLE = this.translate.instant("general.IDLE");
      const DOWN = this.translate.instant("general.DOWN");
      const QA = this.translate.instant("general.QA");
      const SAFETY = this.translate.instant("general.SAFETY");
      const MODERATE = this.translate.instant("general.MODERATE");

      const colors: { [key: string]: number } = {};
      if (totalModerateEvents && this.auth.isModerator()) {
        colors[MODERATE] = totalModerateEvents;
      }
      if (totalQaEvents && this.auth.isQa()) {
        colors[QA] = totalQaEvents;
      }
      if (totalSafetyEvents && this.auth.isSafety()) {
        colors[SAFETY] = totalSafetyEvents;
      }
      if (tool.isGroup) {
        if (groupStatus && !hasSubSystems) {
          if (groupStatus[2]) {
            colors[PM] = groupStatus[2];
          }
          if (groupStatus[1]) {
            colors[IDLE] = groupStatus[1];
          }
          if (groupStatus[3]) {
            colors[DOWN] = groupStatus[3];
          }
        }
      } else {
        if (!hasSubSystems) {
          if (toolStatus == 2) {
            colors[PM] = totalSeverityOpenEvent;
          }
          if (toolStatus == 1) {
            colors[IDLE] = totalSeverityOpenEvent;
          }
          if (toolStatus == 3) {
            colors[DOWN] = totalSeverityOpenEvent;
          }
        } else {
          if (groupStatus && groupStatus[2]) {
            colors[PM] = groupStatus[2];
          }
          if (groupStatus && groupStatus[1]) {
            colors[IDLE] = groupStatus[1];
          }
          if (groupStatus && groupStatus[3]) {
            colors[DOWN] = groupStatus[3];
          }
        }
      }
      if (Object.keys(colors).length > 0) {
        return Object.keys(colors)
          .map((key) => {
            return `${key} (${colors[key]})`;
          })
          .join(", ");
      }
    }
    return null;
  };

  toolLink(params) {
    if (params.rowData.isDeleted) {
      this.confirm
        .show(this.translate.instant("confirm.TOOL.TOOL_DELETED"), {
          type: ConfirmType.CONFIRM_ONLY,
          confirmText: this.translate.instant("shared.OK"),
          defaultBtnClass: "btn-danger",
        })
        .subscribe(() => {});
    } else {
      if (params.rowData.isGroup) {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([
            `/main/maintenance/${params.rowData ? params.rowData.id_num : ""}`,
          ])
        );
        window.open(url, "_blank");
      } else {
        const url = this.router.serializeUrl(
          this.router.createUrlTree([
            `/main/maintenance/tool/${
              params.rowData ? params.rowData.id_num : ""
            }`,
          ])
        );
        window.open(url, "_blank");
      }
    }
  }

  getToolName = (toolId: string) => {
    const t = this.getToolsArray().findIndex(
      (t) => t._id.toString() === toolId.toString()
    );
    return t > -1 ? this.getToolsArray()[t]?.name : "";
  };

  getRelatedGroupIds = async (group) => {
    return new Promise(async (resolve, reject) => {
      let res = [];
      const groups = this.getGroups();
      const grp = groups
        .filter((g) => g.group && group.includes(g.group._id.toString()))
        .filter((g) => !g.isDeleted)
        .map((g) => g._id);
      if (grp.length > 0) {
        res = concat(res, grp);
        const insideGroup: any = await this.getRelatedGroupIds(grp);
        if (insideGroup.length > 0) {
          res = concat(res, insideGroup);
        }
      }
      resolve(res);
    });
  };
}
