import { Procedure } from './procedure';
import { User } from '../../routes/user/login.response';
import { Tool } from './tool';
import { Maintenance } from './maintenance';
import { Department } from './department';
import { EventPart } from './event-part';
import { ICreateMachineStatus } from './machine-status';
import { MaintenanceType } from './maintenance-type';
import { Worker } from './worker';
import { ChecklistItem } from './checklist-item';
import { EventChecklistItem } from './event-checklist';
import { EVENT_STATUS } from '../constants/enums/enums';
import { DynamicDropdown } from './dynamic-dropdown';
import { PmCalender } from './pm-calender';

const DEFAULT_SEVERITY = 0;

export class ToolEvent {
  _id?: string;
  id_num?: Number;
  company?: string;
  tool?: string | Tool;
  type?: string;
  typeId?: any;
  start: Date;
  finish: Date;
  updatedAt?: Date;
  createdAt?: Date;
  problemDesc?: string;
  solutionDesc?: string;
  partsReplaced?: string;
  worker?: string | User;
  responsiblePerson?: string | User | Department;
  responsiblePersonObject?: User | Department;
  responsibleDepartment?: string | Department;
  custom?: any;
  customFields?: any;
  pmCustomFields?: any;
  file?: [];
  maintenance?: string | Maintenance;
  maintenanceObject?: Maintenance;
  checklist?: any;
  done?: boolean;
  isDeleted?: boolean;
  parts?: EventPart[];
  isDirtyFinishDate?: boolean;
  department?: Department | string;
  departmentObject?: Department;
  isWaReporter?: boolean;
  updatedBy?: User;
  createdBy?: User;
  firstChangedAt?: User;
  firstChangedBy?: Date | null;
  machineStatus?: string | null;
  machineStatusObject?: ICreateMachineStatus;
  maintenanceType?: string | null;
  maintenanceTypeObject?: MaintenanceType;
  signaturePerson?: string | User | Worker;
  signaturePersonObject?: User | Worker;
  checklistItems?: EventChecklistItem[];
  signatureId?: string | null;
  controlButton?: string | null;
  scheduleWork?: Date;
  firstSignaturePerson?: string;
  secondSignaturePerson?: string;
  waReporterNumber?: string;
  pmCalender?: string | null;
  isEdit?: boolean;
  hideDataInGraphs?: boolean;
  taskProcedure?: string | Procedure;
  status?: EVENT_STATUS;
  downtime?: number;
  qaApproval?: string;
  qaApprovalObject?: User;
  qaApprovalTime?: Date;
  customTable?: string;
  customTableObject?: DynamicDropdown;
  tempPmCalendar?: string;
  pmCalendar?: PmCalender;
  childEvents?: ToolEvent[];
  parentEvent?: ToolEvent;
  newGeneratedEvent?: ChildEvent[];
  fromSystem?: boolean;
  finishedBy?: User;
  severity?: string;
  breadcrumbs?: string;

  constructor(options: {
    _id?: string,
    id_num?: Number,
    company?: string,
    tool?: string | Tool,
    type?: string,
    typeId?: any,
    start?: Date,
    finish?: Date,
    updatedAt?: Date,
    createdAt?: Date,
    problemDesc?: string,
    solutionDesc?: string,
    partsReplaced?: string,
    worker?: string | User,
    responsiblePerson?: string | User | Department,
    responsiblePersonObject?: User | Department,
    responsibleDepartment?: string | Department,
    custom?: any,
    customFields?: any,
    pmCustomFields?: any,
    file?: [],
    maintenance?: string | Maintenance,
    maintenanceObject?: Maintenance,
    checklist?: any,
    done?: boolean,
    isDeleted?: boolean,
    parts?: EventPart[],
    isDirtyFinishDate?: boolean,
    department?: Department | string,
    departmentObject?: Department,
    isWaReporter?: boolean,
    updatedBy?: User,
    createdBy?: User,
    firstChangedAt?: User,
    firstChangedBy?: Date | null,
    machineStatus?: string | null,
    machineStatusObject?: ICreateMachineStatus,
    maintenanceType?: string | null,
    maintenanceTypeObject?: MaintenanceType,
    signaturePerson?: string | User | Worker,
    signaturePersonObject?: User | Worker,
    checklistItems?: EventChecklistItem[],
    signatureId?: string | null,
    controlButton?: string | null,
    scheduleWork?: Date,
    firstSignaturePerson?: string,
    secondSignaturePerson?: string,
    waReporterNumber?: string,
    pmCalender?: string,
    isEdit?: boolean,
    hideDataInGraphs?: boolean,
    taskProcedure?: string | Procedure,
    status?: EVENT_STATUS,
    downtime?: number,
    qaApproval?: string ,
    qaApprovalObject?: User,
    qaApprovalTime?: Date,
    customTable?: string,
    customTableObject?: DynamicDropdown,
    tempPmCalendar?: string,
    pmCalendar?: PmCalender,
    childEvents?: ToolEvent[],
    parentEvent?: ToolEvent,
    newGeneratedEvent?: ChildEvent[],
    fromSystem?: boolean,
    finishedBy?: User,
    severity?: string,
    breadcrumbs?: string,
  }) {

    this._id = options._id || undefined;
    this.id_num = options.id_num || 0;
    this.company = options.company || null;
    this.tool = options.tool || null;
    this.type = options.type || null;
    this.typeId = options.typeId || null;
    this.start = options.start;
    this.finish = options.finish || null;
    this.createdAt = options.createdAt;
    this.updatedAt = options.updatedAt || null;
    this.problemDesc = options.problemDesc || null;
    this.solutionDesc = options.solutionDesc || null;
    this.partsReplaced = options.partsReplaced || null;
    this.worker = options.worker || null;
    this.responsiblePerson = options.responsiblePerson || null;
    this.responsiblePersonObject = options.responsiblePersonObject || null;
    this.responsibleDepartment = options.responsibleDepartment || null;
    this.custom = options.custom || null;
    this.customFields = options.customFields || {};
    this.pmCustomFields = options.pmCustomFields || {};
    this.file = options.file || null;
    this.maintenance = options.maintenance || null;
    this.maintenanceObject = options.maintenanceObject || null;
    this.checklist = options.checklist || null;
    this.done = options.done || null;
    this.isDeleted = options.isDeleted || false;
    this.parts = options.parts || []
    this.isDirtyFinishDate = options.isDirtyFinishDate || false;
    this.department = options.department || null;
    this.departmentObject = options.departmentObject || null;
    this.isWaReporter = options.isWaReporter || false;
    this.updatedBy = options.updatedBy || null;
    this.createdBy = options.createdBy || null;
    this.firstChangedAt = options.firstChangedAt || null;
    this.firstChangedBy = options.firstChangedBy || null;
    this.machineStatus = options.machineStatus || null;
    this.machineStatusObject = options.machineStatusObject || null;
    this.maintenanceType = options.maintenanceType || null;
    this.maintenanceTypeObject = options.maintenanceTypeObject || null;
    this.signaturePerson = options.signaturePerson || null;
    this.signaturePersonObject = options.signaturePersonObject || null;
    this.checklistItems = options.checklistItems || [];
    this.signatureId = options.signatureId || null;
    this.controlButton = options.controlButton || null;
    this.scheduleWork = options.scheduleWork || null;
    this.firstSignaturePerson = options.firstSignaturePerson || null;
    this.secondSignaturePerson = options.secondSignaturePerson || null;
    this.waReporterNumber = options.waReporterNumber || null;
    this.pmCalender = options.pmCalender || null;
    this.isEdit = options.isEdit || false;
    this.hideDataInGraphs = options.hideDataInGraphs;
    this.taskProcedure = options.taskProcedure || null;
    this.status = options.status || EVENT_STATUS.ACTIVE;
    this.downtime = options.downtime >= 0 ? options.downtime : null;
    this.qaApproval = options.qaApproval || null;
    this.qaApprovalObject = options.qaApprovalObject || null;
    this.qaApprovalTime = options.qaApprovalTime || null;
    this.customTable = options.customTable || null;
    this.customTableObject = options.customTableObject || null;
    this.tempPmCalendar = options.tempPmCalendar || null;
    this.pmCalendar = options.pmCalendar || null;
    this.childEvents = options.childEvents || null;
    this.parentEvent = options.parentEvent || null;
    this.newGeneratedEvent = options.newGeneratedEvent || null;
    this.fromSystem = options.fromSystem || false;
    this.finishedBy = options.finishedBy || null;
    this.severity = options.severity || null;
    this.breadcrumbs = options.breadcrumbs || null;
  }

  static getSeverity(event: ToolEvent, eventTypesItems: any = eventTypes): any {
    return event.machineStatusObject ? event.machineStatusObject.severity : DEFAULT_SEVERITY;
  }
}

export interface ChildEvent{
  tool: string,
  severity: number,
  type: string,
}

export const eventTypes = [
  {
    id: 'failure',
    title: 'Machine Failure',
    name: 'Machine Failure',
    severity: 3
  },
  {
    id: 'sheduled_maintenance',
    title: 'Scheduled Maintenance',
    name: 'Scheduled Maintenance',
    severity: 1
  },
  {
    id: 'unscheduled_maintenance',
    title: 'Unscheduled Maintenance',
    name: 'Unscheduled Maintenance',
    severity: 2
  },
  {
    id: 'comment',
    title: 'Comment',
    name: 'Comment',
    severity: 0
  },
  {
    id: 'maintenance_needed',
    title: 'Maintenance Needed',
    name: 'Maintenance Needed',
    severity: 3
  },
  {
    id: 'on_maintenance',
    title: 'On Maintenance',
    name: 'On Maintenance',
    severity: 2
  },
  {
    id: 'profilactics',
    title: 'Profilactics',
    name: 'Profilactics',
    severity: 2
  },
];

